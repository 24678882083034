import { INIT, START, FIRST_PAGE, SECOND_PAGE, THREE_PAGE, ERROR_PAGE, CHANGE_SCREEN, AUTH_SUCCESS, AUTH_ERROR, FINISH_APPLICATION, RESTORE_APPLICATION, SAVE_APPLICATION } from "../constants/formConstants";
import Axios from '../services/Api.js';

export const start = (token, restore) => async (dispatch) => {
    dispatch({ type: START, payload: token });
    try {
        if (token === null) {
            /*  const username = 'lead_auth';
             const password = '$Ch4ch4s@321$';
             const { data } = await Axios.CreateToken({
                 username,
                 password,
             });
             if (data.message === 'success') {
                 dispatch({ type: AUTH_SUCCESS, payload: data.token });
                 localStorage.setItem('token', data.token);
             } else {
                 dispatch({ type: AUTH_ERROR, payload: '' });
             } */
        } else {
            if (restore === '') {
                const token = localStorage.getItem('token');
                const { data } = await Axios.CheckApplication({
                    token,
                });
                return data;
            } else {
                const token = localStorage.getItem('token');
                const lastName = restore;
                const { data } = await Axios.RestoreData({
                    token,
                    lastName,
                });
                if (data.status === 'success') {
                    localStorage.setItem('token', data.token);
                    dispatch({ type: RESTORE_APPLICATION, payload: data.data, token: data.token });
                } else {
                    dispatch({ type: AUTH_ERROR, payload: '' });
                }
                return data;
            }
        }
    } catch (error) {
        dispatch({ type: AUTH_ERROR, payload: token });
    }
}

function compareObj(a, b) {
    var aKeys = Object.keys(a).sort();
    var bKeys = Object.keys(b).sort();
    if (aKeys.length !== bKeys.length) {
        return false;
    }
    if (aKeys.join('') !== bKeys.join('')) {
        return false;
    }
    for (var i = 0; i < aKeys.length; i++) {
        if (a[aKeys[i]] !== b[bKeys[i]]) {
            return false;
        }
    }
    return true;
}

export const first = (beforeData, application, firstSend) => async (dispatch) => {
    const data = saveData(beforeData, application, firstSend);
    dispatch({ type: FIRST_PAGE, payload: application });
    try {
        /* data.then((e) => {
            if (e === undefined) {
                return;
            }
            if (e.message === 'success') {
                dispatch({ type: FIRST_PAGE, payload: application });
            }
            else {
                dispatch({ type: ERROR_PAGE, payload: data });
            }
        }); */
    } catch (e) { dispatch({ type: FIRST_PAGE, payload: application }); }

    return data;
}

export const saveMail = (beforeData, application, firstSend) => async (dispatch) => {
    const data = saveData(beforeData, application, firstSend);
    dispatch({ type: FIRST_PAGE, payload: application });
    return data;
}

export const second = (data, beforeData) => async (dispatch) => {
    /* if (beforeData !== undefined) {
        saveData(beforeData, data);
    } */
    dispatch({ type: SECOND_PAGE, payload: data });
}

/* export const second = (beforeData, application) => async (dispatch) => {
    console.log(application); return;
    const data = saveData(beforeData, application);
    dispatch({ type: SECOND_PAGE, payload: application });
} */

export const third = (data, beforeData) => async (dispatch) => {
    //const data = saveData(beforeData, application);
    /* if (beforeData !== undefined) {
        saveData(beforeData, data);
    } */
    dispatch({ type: THREE_PAGE, payload: data });
}

export const errorPage = (data) => async (dispatch) => {
    dispatch({ type: ERROR_PAGE, payload: data });
}

export const changeScreen = (application, data, firstScreen = false) => async (dispatch) => {
    saveData({ status: true }, data, false, firstScreen)/* .then((e) => (console.log(e))) */;
    dispatch({ type: CHANGE_SCREEN, payload: application });
}

export const save = (data, beforeData) => async (dispatch) => {
    saveData(beforeData, data)/* .then((val) => (console.log(val))) */;
}

async function saveData(beforeData, application, noScreen, firstScreen = false) {
    var save = true;
    if (compareObj(beforeData, application))
        save = false;

    if (application.nombre !== ''/*  && application.apellido !== '' */) {
        /* if (beforeData.nombre !== application.nombre && beforeData.apellido !== application.apellido && beforeData.telefono !== application.telefono) {
            save = false;
        } */

        if (application.telefono === '' || application.telefono === '____-____') {
            save = false;
        } else {

            for (var i = 0; i < application.telefono.length; i++) {
                if (application.telefono[i] === '_') {
                    save = false;
                }
            }

        }

        if (!save && application.correo !== '') {
            var regex = /^[a-zA-Z0-9][a-zA-Z0-9].*@[a-zA-Z0-9][a-zA-Z0-9].*\.[a-zA-Z0-9][a-zA-Z0-9].*/g;
            if (regex.test(application.correo)) {
                save = true;
            }
        }

        if (noScreen) {
            return { message: 'success', status: 'success' };
        }

        if (save) {
            if (localStorage.getItem('token') === null) {
                if(!firstScreen)
                    return createToken(application);
                else
                    return { message: 'success', status: 'success' };
            } else {
                const token = localStorage.getItem('token');
                const { data } = await Axios.SaveApplication({
                    application,
                    token
                });
                return data;
            }
        }
        return { message: 'error' };
    }
}

async function createToken(applicationData) {
    const username = 'lead_auth';
    const password = '$L3aD@321$';
    const application = applicationData;

    const { data } = await Axios.CreateToken({
        username,
        password,
        application,
    });
    if (data.message === 'success') {
        localStorage.setItem('token', data.token);
    }
    return data;
}

export const createLender = (application) => async (dispatch) => {
    dispatch({ type: FINISH_APPLICATION, payload: application });
    const data = saveForm(application);
    try {
        data.then((e) => {
            console.log(e);
            if (e === undefined) {
                dispatch({ type: FIRST_PAGE, payload: application });
                return;
            }
            if (e.message === 'success') {
                dispatch({ type: FIRST_PAGE, payload: application });
            }
            else {
                dispatch({ type: ERROR_PAGE, payload: data });
            }
        });
    } catch (e) { dispatch({ type: ERROR_PAGE, payload: data }); }
}

async function saveForm(application) {
    var save = true;
    /* if (application.direccion === '' ||
        application.zona === '' ||
        application.departamento === '' ||
        application.municipio === '') {

        save = false;
    } */

    if (save) {
        const token = localStorage.getItem('token');
        const { data } = await Axios.SaveLead({
            application,
            token
        });
        return data;
    }
}